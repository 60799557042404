import { useState, useEffect } from "react";
import { useTag } from "launched";

import { Sliders, HardDrive, GitBranch } from "react-feather";
import { Text, Image } from "launched/components";
import { Rich } from "./TiptapEditor";
import ReactJson from "react-json-view";

import type { Tag, TagData } from "launched";

import Launched from "launched";
import { allIcons } from "./IconEditor";

function Feature({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: number;
}) {
  const Icon = allIcons[icon];

  return (
    <li className="flex flex-col gap-2.5">
      <span
        data-key="icon"
        className="grid h-10 w-10 place-items-center rounded-full bg-brand-light/10 text-lg text-brand-mid"
      >
        <Icon style={{ width: "1em", height: "1em" }} />
      </span>
      <h3 data-key="title" className="text-xl">
        {title}
      </h3>
      <p
        data-key="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </li>
  );
}

const f = [
  {
    title: "Controlled control.",
    description:
      "Launched is a client-side content management system. You decide what content is editable.",
    icon: Sliders,
  },
  {
    title: "Entirely yours.",
    description:
      "Launched generates the content, you decide how and where it should be stored. It's your data.",
    icon: HardDrive,
  },
  {
    title: "All open-source.",
    description:
      "Launched is open-source. You can contribute to the project, or fork it and make it your own.",
    icon: GitBranch,
  },
];

export default function Demo() {
  const [tagData, setTagData] = useState<Record<string, TagData>>({
    message: "Update some data to get started.",
  } as any);

  const defaultFeatures = f.map(({ title, icon, description }) => ({
    title,
    icon: { type: "icon", value: allIcons.indexOf(icon) },
    description: { value: `<p>${description}</p>`, type: "rich" },
  }));
  const [features, featuresTag] = useTag("features", defaultFeatures, {
    arrayMutable: true,
  });

  function onDataUpdate(rawData: Record<string, Tag>) {
    const data = Object.fromEntries(
      Object.entries(rawData).map(([key, tag]) => [key, tag.data]),
    );

    setTagData(data);
  }

  useEffect(() => {
    Launched.events.on("data:update", onDataUpdate);

    return () => {
      Launched.events.off("data:update", onDataUpdate);
    };
  }, []);

  return (
    <div className="flex flex-col gap-10 rounded-2xl bg-black/50">
      <div className="flex flex-col gap-10 rounded-2xl bg-white p-10 text-bg sm:gap-20 sm:pt-20">
        <div className="flex grid-cols-2 flex-col-reverse gap-20 sm:px-10 lg:grid">
          <div className="flex flex-col gap-5">
            <Text element="h2" tag="title" className="text-5xl">
              Welcome to Launched.
            </Text>
            <Rich tag="description" className="text-lg">
              {
                "<p>Launched makes client site content editable. It's free, easy to integrate, and user-friendly. Switch to edit mode to see it in action.</p>"
              }
            </Rich>
          </div>
          <Image
            classNames={{
              container:
                "h-full w-full flex items-center justify-center rounded-2xl rounded-tl-[200px] bg-bg sm:mb-0 -mb-10",
              image: "animate-hover-in-place w-3/4",
            }}
            src="/chair.png"
            alt=""
            tag="hero image"
          />
        </div>
        <ul ref={featuresTag} className="grid gap-10 sm:px-10 lg:grid-cols-3">
          {features.map((props, i: number) => (
            <Feature key={i} {...props} />
          ))}
        </ul>
        <div className="relative flex w-full overflow-hidden rounded-2xl bg-bg p-5 sm:p-10">
          <div className="z-10 flex w-72 flex-col gap-5">
            <Text
              element="h3"
              tag="banner title"
              className="w-max text-3xl text-text-primary sm:text-5xl"
            >
              Get started.
            </Text>
            <Rich tag="banner text" className="text-text-secondary">
              {
                "<p>Check out our quickstart guides to get up and running with Launched.</p>"
              }
            </Rich>
            <button className="btn w-max text-text-secondary">
              Get started
            </button>
          </div>
          <img
            className="absolute right-0 top-0 -my-10 -ml-20 h-full object-cover md:static"
            src="/space.png"
            alt=""
          />
        </div>
      </div>
      <div id="dataview" className="p-10 pt-0 font-mono text-white">
        <ReactJson
          src={tagData}
          theme={{
            base00: "transparent",
            base01: "white",
            base02: "white",
            base03: "white",
            base04: "white",
            base05: "white",
            base06: "white",
            base07: "white",
            base08: "white",
            base09: "#FF7F24",
            base0A: "white",
            base0B: "white",
            base0C: "white",
            base0D: "white",
            base0E: "white",
            base0F: "white",
          }}
          collapsed={1}
          name={null}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </div>
    </div>
  );
}
